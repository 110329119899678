import { Box, Divider, Drawer, Typography } from "@mui/material";
import * as React from "react";
import useSWR from "swr";

import { NavCollection } from "components/NavSection";
import { SkeletonLoader } from "components/loaders/SkeletonLoader";

import { createSidebarRoutes } from "configs/sidebarRoutes";
import { useAuth } from "hooks/useAuth";
import { useInstanceData } from "hooks/useInstanceData";
import { useSettings } from "hooks/useSettings";
import { useSlugRouter } from "hooks/useSlugRouter";
import Logo from "icons/Logo";

import { ApiError } from "types/ApiErrors";
import { CustomMenuType } from "types/Tables";
import { FlApi } from "types/api";

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

export const DashboardSidebar: React.FC<DashboardSidebarProps> = ({
  onMobileClose,
  openMobile,
}) => {
  const { client, permissions } = useAuth();
  const { instance } = useInstanceData();
  const { settings } = useSettings();
  const router = useSlugRouter();

  const { data: customMenus, error: customMenuError } = useSWR<
    FlApi.CustomReports.CustomMenu[],
    ApiError
  >(`custom-reports/custom-menus?language=${settings.language}`);

  const accessibleKeys = React.useMemo(
    () => [
      ...permissions.menus.map((menu) => menu.menu_id),
      ...permissions.customMenus.map((menu) => menu.custom_menu_id),
    ],
    [permissions.customMenus, permissions.menus]
  );

  const sidebarRoutes = React.useMemo(
    () =>
      createSidebarRoutes(
        customMenus?.filter((menu) => menu.type === CustomMenuType.REPORT) ??
          [],
        customMenus?.filter((menu) => menu.type === CustomMenuType.TREND) ?? []
      ),
    [customMenus]
  );

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          justifyContent: "center",
          p: 2,
          display: { lg: "none", xs: "flex" },
        }}
      >
        <Logo
          sx={{
            height: 26,
            width: 229,
          }}
        />
      </Box>
      <Box sx={{ p: 2 }}>
        <Box
          sx={{
            alignItems: "center",
            backgroundColor: "background.default",
            borderRadius: 1,
            display: "flex",
            overflow: "hidden",
            p: 2,
          }}
        >
          <Box sx={{ ml: 2 }}>
            <Typography color="textPrimary" variant="subtitle2">
              {client?.firstname} {client?.lastname}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              {`${instance?.instance_name ?? ""} - ${
                process.env.NEXT_PUBLIC_RELEASE_VERSION ?? "local"
              }`}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <SkeletonLoader loading={!customMenus} error={customMenuError}>
          <NavCollection
            visibleKeys={accessibleKeys}
            sidebarRoutes={sidebarRoutes}
            pathname={router.pathname.replace("/[slug]", "")}
            slug={router.slug}
          />
        </SkeletonLoader>
      </Box>
    </Box>
  );

  return (
    <>
      <Drawer
        anchor="left"
        onClose={onMobileClose}
        open={openMobile}
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            width: 280,
          },
        }}
        sx={{ display: { lg: "none", xs: "block" } }}
        variant="temporary"
      >
        {content}
      </Drawer>
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            height: "calc(100% - 64px) !important",
            top: "64px !Important",
            width: 280,
          },
        }}
        sx={{ display: { lg: "block", xs: "none" } }}
        variant="persistent"
      >
        {content}
      </Drawer>
    </>
  );
};
