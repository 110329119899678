import { createSvgIcon } from "@mui/material";

const French = createSvgIcon(
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="36"
    viewBox="0 0 3020 2020"
  >
    <rect fill="rgb(0%,14%,58%)" x="0" y="0" width="1010" height="2000" />
    <rect fill="rgb(97%,97%,97%)" x="1000" y="0" width="1010" height="2000" />
    <rect fill="rgb(93%,16%,22%)" x="2000" y="0" width="1000" height="2000" />
    <rect
      fill="none"
      stroke="rgb(55%,55%,55%)"
      strokeWidth="10"
      x="0"
      y="0"
      width="3000"
      height="2000"
    />
  </svg>,
  "French"
);

export default French;
