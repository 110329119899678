import { List, ListSubheader } from "@mui/material";
import type { ListProps } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { NavItem } from "./NavItem";

import { MenuScope } from "types/Tables";

interface Item {
  path?: string;
  icon?: React.ReactNode;
  info?: React.ReactNode;
  children?: Item[];
  key: MenuScope;
  title: string;
}

export interface NavSectionProps extends ListProps {
  items: Item[];
  pathname: string;
  title: string;
  slug: string;
  visibleKeys: MenuScope[];
}

interface ParentRouteProps {
  pathname: string;
  items: Item[];
  depth?: number;
  slug: string;
  visibleKeys: MenuScope[];
}

const ParentRoute = ({
  depth = 0,
  items,
  pathname,
  slug,
  visibleKeys,
}: React.PropsWithChildren<ParentRouteProps>) => (
  <List disablePadding>
    {items
      .filter((item) => visibleKeys.includes(item.key))
      .map(
        (item) => (
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          <ChildRoute
            key={item.key}
            item={item}
            pathname={pathname}
            depth={depth}
            slug={slug}
            visibleKeys={visibleKeys}
          />
        ),
        []
      )}
  </List>
);

interface ChildRouteProps {
  pathname: string;
  item: Item;
  depth: number;
  slug: string;
  visibleKeys: MenuScope[];
}

const ChildRoute = ({
  pathname,
  item,
  depth,
  slug,
  visibleKeys,
}: React.PropsWithChildren<ChildRouteProps>) => {
  const { t } = useTranslation();
  if (item.children) {
    if (
      !item.children.some((childItem) => visibleKeys.includes(childItem.key))
    ) {
      return null;
    }
    const partialMatch = item.path ? pathname.includes(item.path) : false;
    return (
      <NavItem
        active={partialMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        open={partialMatch}
        path={slug ? `/${slug}${String(item.path)}` : String(item.path)}
        title={t(item.title)}
      >
        <ParentRoute
          depth={depth + 1}
          items={item.children}
          pathname={pathname}
          slug={slug}
          visibleKeys={visibleKeys}
        />
      </NavItem>
    );
  }

  return (
    <NavItem
      active={item.path === pathname}
      depth={depth}
      icon={item.icon}
      info={item.info}
      path={slug ? `/${slug}${String(item.path)}` : String(item.path)}
      title={t(item.title)}
    />
  );
};

const NavSection = ({
  items,
  pathname,
  title,
  slug,
  visibleKeys,
}: React.PropsWithChildren<NavSectionProps>) => (
  <List
    subheader={
      <ListSubheader
        disableGutters
        disableSticky
        sx={{
          color: "text.primary",
          fontSize: "0.75rem",
          lineHeight: 2.5,
          fontWeight: 700,
          textTransform: "uppercase",
        }}
      >
        {title}
      </ListSubheader>
    }
  >
    <ParentRoute
      items={items}
      pathname={pathname}
      slug={slug}
      visibleKeys={visibleKeys}
    />
  </List>
);

interface NavCollectionProps {
  sidebarRoutes: Pick<NavSectionProps, "items" | "title">[];
  visibleKeys: MenuScope[];
  pathname: string;
  slug: string;
}

export const NavCollection = ({
  sidebarRoutes,
  visibleKeys,
  pathname,
  slug,
}: React.PropsWithChildren<NavCollectionProps>) => {
  const { t } = useTranslation();
  return (
    <>
      {sidebarRoutes.map((section) => {
        const items = section.items.filter((item) =>
          visibleKeys.includes(item.key)
        );

        if (items.length === 0) {
          return null;
        }

        return (
          <NavSection
            key={section.title}
            pathname={pathname}
            sx={{
              "& + &": {
                mt: 3,
              },
            }}
            items={items}
            title={t(section.title)}
            slug={slug}
            visibleKeys={visibleKeys}
          />
        );
      })}
    </>
  );
};
