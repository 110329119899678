import { Button } from "@mui/material";
import { experimentalStyled } from "@mui/material/styles";

export const RoundedButton = experimentalStyled(Button)(({ theme }) => ({
  borderRadius: "5em",
  "&.themeOutline": {
    width: "100%",
    borderColor: theme.palette.primary.main,
  },
  "&.themeOutline:hover": {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  "&.qnaireSaveProgress:disabled": {
    borderColor: "#b5b6b7",
  },
  "&.qnaireSubmit": {
    width: "100%",
  },
  "&.addItem": {
    backgroundColor: "#096532",
    paddingLeft: 15,
    color: "#FFFFFF",
    size: "small",
  },
  "&.addItem:hover": {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  "&.delete": {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
  },
  "&.delete:hover": {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
  },
  "&.contrast": {
    color: theme.palette.primary.contrastText,
  },
}));
