import { Box, Switch } from "@mui/material";
import * as React from "react";

import { THEMES } from "../constants/themes";
import { useSettings } from "../hooks/useSettings";
import MoonIcon from "../icons/Moon";
import SunIcon from "../icons/Sun";

export const ThemeSwitcher: React.FC = () => {
  const { settings, saveSettings } = useSettings();

  const handleSwitch = () => {
    if (settings.theme === THEMES.LIGHT) {
      saveSettings({ ...settings, theme: THEMES.DARK });
    } else if (settings.theme === THEMES.DARK) {
      saveSettings({ ...settings, theme: THEMES.LIGHT });
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <SunIcon fontSize="small" />
      <Switch
        checked={settings.theme === THEMES.DARK}
        onChange={handleSwitch}
        name=""
        color="primary"
      />
      <MoonIcon fontSize="small" />
    </Box>
  );
};
