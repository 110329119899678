import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useRef, useState } from "react";
import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { ThemeSwitcher } from "components/ThemeSwitcher";

import { ROUTES } from "constants/routes";
import { useAuth } from "hooks/useAuth";
import { useSlugRouter } from "hooks/useSlugRouter";
import CogIcon from "icons/Cog";
import { stringAvatar } from "utils/stringAvatar";

export const AccountPopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const router = useSlugRouter();
  const { client, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      await router.push(ROUTES.AUTH_LOGIN);
    } catch (err) {
      enqueueSnackbar(t("profile.popover_logout_failed.error"), {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: "center",
          display: "flex",
        }}
      >
        <Avatar
          {...stringAvatar(
            `${client?.firstname ?? ""} ${client?.lastname ?? ""}`
          )}
        />
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        <Box
          sx={{
            p: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography color="textPrimary" variant="subtitle2">
            {client?.firstname} {client?.lastname}
          </Typography>
          <ThemeSwitcher />
        </Box>
        <Divider />
        <Box sx={{ mt: 2 }}>
          <MenuItem onClick={async () => router.push(ROUTES.PROFILE)}>
            <ListItemIcon>
              <CogIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  {t("profile.popover_settings.label")}
                </Typography>
              }
            />
          </MenuItem>
        </Box>
        <Box sx={{ p: 2 }}>
          <Button
            color="primary"
            fullWidth
            onClick={handleLogout}
            variant="outlined"
          >
            {t("profile.popover_logout.button")}
          </Button>
        </Box>
      </Popover>
    </>
  );
};
