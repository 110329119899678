import { experimentalStyled } from "@mui/material/styles";
import React from "react";

import { DashboardNavbar } from "./DashboardNavbar";
import { DashboardSidebar } from "./DashboardSidebar";

import { AuthenticatedRoute } from "components/authentication/AuthenticatedRoute";

import { useAuth } from "hooks/useAuth";

const DashboardLayoutRoot = experimentalStyled("div")(() => ({
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const DashboardLayoutWrapper = experimentalStyled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: "64px",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: "280px",
  },
}));

const DashboardLayoutContainer = experimentalStyled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const DashboardLayoutContent = experimentalStyled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
  position: "relative",
  WebkitOverflowScrolling: "touch",
});

interface DashboardLayoutProps {
  title: string;
}

export const DashboardLayout: React.FC<DashboardLayoutProps> = ({
  children,
  title,
}) => {
  const { isBaseStudent } = useAuth();

  const [isSidebarMobileOpen, setIsSidebarMobileOpen] =
    React.useState<boolean>(false);

  const handleMobileClose = () => {
    setIsSidebarMobileOpen(false);
  };

  const handleMobileOpen = () => {
    setIsSidebarMobileOpen(true);
  };

  if (!isBaseStudent) {
    return (
      <AuthenticatedRoute>
        <title>{title} | Flourishing Life</title>
        <DashboardLayoutRoot>
          <DashboardNavbar onSidebarMobileOpen={handleMobileOpen} />
          <DashboardSidebar
            onMobileClose={handleMobileClose}
            openMobile={isSidebarMobileOpen}
          />

          <DashboardLayoutWrapper>
            <DashboardLayoutContainer>
              <DashboardLayoutContent sx={{ my: 3, mx: 3 }}>
                {children}
              </DashboardLayoutContent>
            </DashboardLayoutContainer>
          </DashboardLayoutWrapper>
        </DashboardLayoutRoot>
      </AuthenticatedRoute>
    );
  }
  return (
    <AuthenticatedRoute>
      <DashboardLayoutRoot>
        <DashboardNavbar
          onSidebarMobileOpen={() => {
            setIsSidebarMobileOpen(true);
          }}
        />

        <DashboardLayoutContainer>
          <DashboardLayoutContent sx={{ my: 9, mx: 3 }}>
            {children}
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutRoot>
    </AuthenticatedRoute>
  );
};
