import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { useSettings } from "hooks/useSettings";
import English from "icons/English";
import French from "icons/French";

import { Language } from "types/Language";

interface LanguageIcon {
  icon: React.ReactElement;
  label: string;
  code: Language;
}

const languageOptions: Record<Language, LanguageIcon> = {
  en: {
    code: "en",
    icon: <English />,
    label: "English",
  },
  fr: {
    code: "fr",
    icon: <French />,
    label: "Français",
  },
};

export const LanguagePopover: React.FC = () => {
  const { settings, saveSettings } = useSettings();
  const anchorRef = React.useRef<HTMLButtonElement | null>(null);
  const { i18n } = useTranslation();
  const [open, setOpen] = React.useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeLanguage = async (language: Language): Promise<void> => {
    await i18n.changeLanguage(language);
    saveSettings({ ...settings, language });
    setOpen(false);
  };

  const selectedOption =
    languageOptions[settings.language ?? (i18n.language as Language)];

  return (
    <>
      <IconButton onClick={handleOpen} ref={anchorRef}>
        <Box
          sx={{
            display: "flex",
            height: 36,
            width: 36,
            "& svg": {
              width: "100%",
            },
          }}
        >
          {selectedOption.icon}
        </Box>
      </IconButton>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        {Object.values(languageOptions).map((language) => (
          <MenuItem
            onClick={async () => handleChangeLanguage(language.code)}
            key={language.code}
          >
            <ListItemIcon>
              <Box>{languageOptions[language.code].icon}</Box>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  {languageOptions[language.code].label}
                </Typography>
              }
            />
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};
