import { NavSectionProps } from "components/NavSection";

import { ROUTES } from "constants/routes";
import ChartPie from "icons/ChartPie";
import DashboardIcon from "icons/ChartSquareBar";
import ClipboardIcon from "icons/ClipboardList";
import FolderOpenIcon from "icons/FolderOpen";
import PlusIcon from "icons/Plus";
import CartIcon from "icons/ShoppingCart";
import UserIcon from "icons/Users";

import { FlApi } from "types/api";

export const createSidebarRoutes = (
  customReportMenus: FlApi.CustomReports.CustomMenu[],
  customTrendMenus: FlApi.CustomReports.CustomMenu[]
): Pick<NavSectionProps, "items" | "title">[] => [
  {
    title: "leftnav.flourishing_life.header",
    items: [
      {
        title: "leftnav.dashboard_section.title",
        icon: <DashboardIcon fontSize="small" />,
        key: "dashboard",
        path: ROUTES.DASHBOARD_ROOT,
        children: [
          {
            title: "leftnav.admin_dashboard.title",
            path: ROUTES.DASHBOARD_ADMIN,
            key: "admin_dashboard",
          },
          {
            title: "leftnav.user_dashboard.title",
            path: ROUTES.DASHBOARD_USER,
            key: "user_dashboard",
          },
          {
            title: "leftnav.flourishing_profile.title",
            path: ROUTES.FLOURISHING_PROFILE,
            key: "flourishing_profile",
          },
        ],
      },
      {
        title: "leftnav.reports.title",
        icon: <FolderOpenIcon fontSize="small" />,
        key: "reports",
        path: ROUTES.REPORTS_ROOT,
        children: [
          {
            title: "leftnav.flourishing_summary.title",
            path: ROUTES.REPORTS_FLOURISHING_SUMMARY,
            key: "reports_flourishing_summary",
          },
          {
            title: "leftnav.flourishing_mindset.title",
            path: ROUTES.REPORTS_FLOURISHING_MINDSETS,
            key: "reports_flourishing_mindsets",
          },
          {
            title: "leftnav.resilience_capacity.title",
            path: ROUTES.REPORTS_RESILIENCE_CAPACITY,
            key: "reports_resiliency_capacity",
          },
          {
            title: "leftnav.performance_capacity.title",
            path: ROUTES.REPORTS_PERFORMANCE_CAPACITY,
            key: "reports_performance_capacity",
          },
          {
            title: "leftnav.well_being_factors.title",
            path: ROUTES.REPORTS_SEVEN_WELL_BEING_FACTORS,
            key: "reports_seven_well_being",
          },
          {
            title: "leftnav.core_competencies.title",
            path: ROUTES.REPORTS_CORE_COMPETENCY,
            key: "reports_core_competency",
          },
          {
            title: "leftnav.nine_habits.title",
            path: ROUTES.REPORTS_NINE_HABITS,
            key: "reports_nine_habits",
          },
          {
            title: "leftnav.custom_questions.title",
            path: ROUTES.REPORTS_CUSTOM_QUESTIONS,
            key: "reports_custom_questions",
          },
          {
            title: "leftnav.client_list.title",
            path: ROUTES.REPORTS_CLIENT_LIST,
            key: "reports_client_list",
          },
          {
            title: "custom_reports.title.label",
            icon: <FolderOpenIcon fontSize="small" />,
            key: "reports",
            path: ROUTES.REPORTS_ROOT,
            children: customReportMenus.map((menu) => ({
              title: menu.name,
              path: `${ROUTES.REPORTS_CUSTOM_BASE}?custom_report_id=${menu.custom_report_id}`,
              key: menu.custom_menu_id,
            })),
          },
        ],
      },
      {
        title: "leftnav.trends.title",
        icon: <FolderOpenIcon fontSize="small" />,
        key: "trends",
        path: ROUTES.REPORTS_ROOT,
        children: [
          {
            title: "leftnav.trends.flourishing_summary",
            path: ROUTES.TRENDS_FLOURISHING_SUMMARY,
            key: "trends_flourishing_summary",
          },
          {
            title: "leftnav.trends.flourishing_mindset",
            path: ROUTES.TRENDS_FLOURISHING_MINDSET,
            key: "trends_flourishing_mindset",
          },
          {
            title: "leftnav.trends.resilience_capacity",
            path: ROUTES.TRENDS_RESILIENCE_CAPACITY,
            key: "trends_resiliency_capacity",
          },
          {
            title: "leftnav.trends.performance_capacity",
            path: ROUTES.TRENDS_PERFORMANCE_CAPACITY,
            key: "trends_performance_capacity",
          },

          {
            title: "leftnav.trends.seven_well_being_factors",
            path: ROUTES.TRENDS_SEVEN_WELL_BEING_FACTORS,
            key: "trends_seven_well_being_factors",
          },
          {
            title: "leftnav.trends.core_competency",
            path: ROUTES.TRENDS_CORE_COMPETENCY,
            key: "trends_core_competency",
          },

          {
            title: "leftnav.trends.nine_habits",
            path: ROUTES.TRENDS_NINE_HABITS,
            key: "trends_nine_habits",
          },
          {
            title: "Custom Trend Reports",
            icon: <FolderOpenIcon fontSize="small" />,
            key: "trends",
            path: ROUTES.REPORTS_ROOT,
            children: customTrendMenus.map((menu) => ({
              title: menu.name,
              path: `${ROUTES.TRENDS_CUSTOM_BASE}?custom_report_id=${menu.custom_report_id}`,
              key: menu.custom_menu_id,
            })),
          },
        ],
      },
    ],
  },
  {
    title: "leftnav.questionnaire.header",
    items: [
      {
        title: "leftnav.assign_questionnaire.title",
        icon: <PlusIcon fontSize="small" />,
        path: ROUTES.TESTS_ASSIGN,
        key: "clients_assign",
      },
      {
        title: "leftnav.questionnaire_status.title",
        icon: <ChartPie fontSize="small" />,
        path: ROUTES.TESTS_STATUS,
        key: "questionnaire_status",
      },
      {
        title: "leftnav.list_questionnaire.title",
        icon: <ClipboardIcon fontSize="small" />,
        path: ROUTES.TESTS_LIST,
        key: "survey_admin_list",
      },
      {
        title: "Custom Report Builder",
        icon: <FolderOpenIcon fontSize="small" />,
        path: ROUTES.CUSTOM_REPORTS,
        key: "custom_reports",
      },
    ],
  },
  {
    title: "leftnav.client_management.header",
    items: [
      {
        title: "leftnav.user_manager.title",
        icon: <UserIcon fontSize="small" />,
        path: ROUTES.CLIENT_MANAGER,
        key: "client_manager",
      },
      {
        title: "leftnav.join_code.title",
        icon: <ClipboardIcon fontSize="small" />,
        path: ROUTES.CLIENT_JOIN_CODE,
        key: "join_code",
      },
    ],
  },
  {
    title: "leftnav.admin.header",
    items: [
      {
        title: "leftnav.org_manager.title",
        icon: <UserIcon fontSize="small" />,
        path: ROUTES.INSTANCE_LIST,
        key: "instance_manager",
      },
      {
        title: "leftnav.licence_manager.title",
        icon: <CartIcon fontSize="small" />,
        path: ROUTES.LICENSE_MANAGER,
        key: "license_manager",
      },
    ],
  },
];
