import { ReportType } from "./Instance";
import { Language } from "./Language";
import { CustomQuestionTypes } from "./api/test";

/**
 * Reference types for tables
 */
export interface Industry {
  industry_id: number;
  name: string;
}

export interface Logos {
  engURL?: string | null;
  frURL?: string | null;
}
export interface BrandingSettings {
  color?: string | null;
  logo?: Logos | null;
}

export interface BrandingSettingsPut {
  color?: string | null;
  engLogo?: File | null;
  frLogo?: File | null;
}

export type LanguageJson = Partial<Record<Language, Record<string, string>>>;
export interface Instance {
  instance_id: number;
  slug: string;
  region: string;
  cognito_client_app_id: string;
  cognito_identity_id: string;
  language_json: LanguageJson;
  is_anonymous: AnonymityLevel;
  industry_id: number;
  branding_setting?: BrandingSettings | null;
  instance_name: string;
  business_name?: string | null;
  contact?: string | null;
  phone?: string | null;
  email?: string | null;
  address?: string | null;
  postalcode?: string | null;
  city?: string | null;
  province?: string | null;
  country?: string | null;
  date_created: string;
  report_type: ReportType;
  gap_type: GapTypes;
  trend_graph_type: TrendGraphTypes;
}

export interface Region {
  region_id: string;
  name: string;
}

export interface ReadingLevel {
  reading_level_id: number;
  instance_id: number;
}

export interface FawbsTestJson {
  completedHtml: string;
  pages: FawbsTestJsonPage[];
  pageNextText: string;
  startSurveyText: string;
  title: string;
  description?: string;
  version?: string;
  name?: string;
}

export interface FawbsTestJsonPage {
  elements: FawbsTestQuestion[];
  description?: string;
}

export interface FawbsTestQuestion {
  name: string;
  title: string;
  choices: FawbsTestChoice[];
  readonly: boolean;
  question_type?: CustomQuestionTypes;
  gap?: boolean;
  positive_text?: string | null;
  negative_text?: string | null;
}

export interface FawbsTestChoice {
  value: string;
  text?: string;
}

export interface FawbsTest {
  fawbstest_id: number;
  instance_id: number;
  jsonvalue: FawbsTestJson;
  name: string;
  // Rename to version
  testversion: string;
  // Rename to description
  testdescription: string;
  reading_level_id: number;
  // Remove
  submitdate: string;
}

export interface FawbsTestYearMonth extends FawbsTest {
  testMonth: number;
  testYear: number;
}

export interface ReadingLevelLang {
  reading_level_id: number;
  name: string;
  lang: Language;
}

export type DefaultQuestionId =
  | "Q101A2B3C1"
  | "Q101A2B3C2"
  | "Q101A2B4C1"
  | "Q101A2B4C2"
  | "Q101A2B5C1"
  | "Q101A2B5C2"
  | "Q101A3B3C1"
  | "Q101A3B3C2"
  | "Q101A3B4C1"
  | "Q101A3B4C2"
  | "Q101A3B5C1"
  | "Q101A3B5C2"
  | "Q101A4B3C1"
  | "Q101A4B3C2"
  | "Q101A4B4C1"
  | "Q101A4B4C2"
  | "Q101A4B5C1"
  | "Q101A4B5C2"
  | "Q101A5B3C1"
  | "Q101A5B3C2"
  | "Q101A5B4C1"
  | "Q101A5B4C2"
  | "Q101A5B6C1"
  | "Q101A5B6C2"
  | "Q102A2B3C1"
  | "Q102A2B3C2"
  | "Q102A2B4C1"
  | "Q102A2B4C2"
  | "Q102A2B5C1"
  | "Q102A2B5C2"
  | "Q102A3B3C1"
  | "Q102A3B3C2"
  | "Q102A3B4C1"
  | "Q102A3B4C2"
  | "Q102A3B5C1"
  | "Q102A3B5C2"
  | "Q102A4B3C1"
  | "Q102A4B3C2"
  | "Q102A4B4C1"
  | "Q102A4B4C2"
  | "Q102A5B3C1"
  | "Q102A5B3C2"
  | "Q102A5B4C1"
  | "Q102A5B4C2"
  | "Q102A5B5C1"
  | "Q102A5B5C2"
  | "Q102A6B3C1"
  | "Q102A6B3C2"
  | "Q102A6B4C1"
  | "Q102A6B4C2"
  | "Q102A6B5C1"
  | "Q102A6B5C2"
  | "Q103A2B3C1"
  | "Q103A2B3C2"
  | "Q103A2B4C1"
  | "Q103A2B4C2"
  | "Q103A2B5C1"
  | "Q103A2B5C2"
  | "Q103A2B6C1"
  | "Q103A2B6C2"
  | "Q103A2B7C1"
  | "Q103A2B7C2"
  | "Q103A2B8C1"
  | "Q103A2B8C2"
  | "Q103A3B3C1"
  | "Q103A3B3C2"
  | "Q103A3B4C1"
  | "Q103A3B4C2"
  | "Q103A3B5C1"
  | "Q103A3B5C2"
  | "Q103A3B6C1"
  | "Q103A3B6C2"
  | "Q103A3B7C1"
  | "Q103A3B7C2"
  | "Q103A3B8C1"
  | "Q103A3B8C2"
  | "Q103A4B3C1"
  | "Q103A4B3C2"
  | "Q103A4B4C1"
  | "Q103A4B4C2"
  | "Q103A4B5C1"
  | "Q103A4B5C2"
  | "Q103A4B6C1"
  | "Q103A4B6C2"
  | "Q103A4B7C1"
  | "Q103A4B7C2"
  | "Q103A4B8C1"
  | "Q103A4B8C2";

export interface DefaultQuestion {
  question_id: number;
  fawid: DefaultQuestionId;
  question: string;
  reverse: boolean;
  industry_id: number;
  reading_level_id: number;
  lang: Language;
}

export interface Gender {
  gender_id: number;
  instance_id: number;
}

export interface GenderLang {
  gender_id: number;
  name: string;
  lang: Language;
}

export interface Client {
  client_id: number;
  instance_id: number;
  cognito_id?: string | null;
  username: string;
  password?: string | null;
  parent_id: number;
  firstname?: string | null;
  lastname?: string | null;
  age?: string | null;
  gender?: number | null;
  state?: string | null;
  homeaddress?: string | null;
  postalcode?: string | null;
  id?: string | null;
  driverlic?: string | null;
  dob?: string | null;
  city?: string | null;
  province?: string | null;
  email?: string | null;
  birthcountry?: string | null;
  birthcity?: string | null;
  homephone?: string | null;
  workphone?: string | null;
  mobilephone?: string | null;
  preferredlanguage?: Language | null;
  additional1?: number | null;
  additional2?: number | null;
  additional3?: number | null;
  additional4?: number | null;
  additional5?: number | null;
  additional6?: number | null;
  additional7?: number | null;
  additional8?: number | null;
  additional9?: number | null;
  additional10?: number | null;
  additional11?: number | null;
  additional12?: number | null;
  additional13?: number | null;
  additional14?: number | null;
  additional15?: number | null;
  additional16?: number | null;
  additional17?: number | null;
  additional18?: number | null;
  additional19?: number | null;
  additional20?: number | null;
  is_deleted: boolean;
  anonymity_opt_out: boolean;
  created_at: string;
  updated_at: string;
  unique_id?: string | null;
}

export interface YouthResults {
  youth_results_id: number;
  client_id: number;
  fawbstest_id: number;
  Q101A2B3C1: number | null;
  Q101A2B3C2: number | null;
  Q101A2B4C1: number | null;
  Q101A2B4C2: number | null;
  Q101A2B5C1: number | null;
  Q101A2B5C2: number | null;
  Q101A3B3C1: number | null;
  Q101A3B3C2: number | null;
  Q101A3B4C1: number | null;
  Q101A3B4C2: number | null;
  Q101A3B5C1: number | null;
  Q101A3B5C2: number | null;
  Q101A4B3C1: number | null;
  Q101A4B3C2: number | null;
  Q101A4B4C1: number | null;
  Q101A4B4C2: number | null;
  Q101A4B5C1: number | null;
  Q101A4B5C2: number | null;
  Q101A5B3C1: number | null;
  Q101A5B3C2: number | null;
  Q101A5B4C1: number | null;
  Q101A5B4C2: number | null;
  Q101A5B6C1: number | null;
  Q101A5B6C2: number | null;
  Q102A2B3C1: number | null;
  Q102A2B3C2: number | null;
  Q102A2B4C1: number | null;
  Q102A2B4C2: number | null;
  Q102A2B5C1: number | null;
  Q102A2B5C2: number | null;
  Q102A3B3C1: number | null;
  Q102A3B3C2: number | null;
  Q102A3B4C1: number | null;
  Q102A3B4C2: number | null;
  Q102A3B5C1: number | null;
  Q102A3B5C2: number | null;
  Q102A4B3C1: number | null;
  Q102A4B3C2: number | null;
  Q102A4B4C1: number | null;
  Q102A4B4C2: number | null;
  Q102A5B3C1: number | null;
  Q102A5B3C2: number | null;
  Q102A5B4C1: number | null;
  Q102A5B4C2: number | null;
  Q102A5B5C1: number | null;
  Q102A5B5C2: number | null;
  Q102A6B3C1: number | null;
  Q102A6B3C2: number | null;
  Q102A6B4C1: number | null;
  Q102A6B4C2: number | null;
  Q102A6B5C1: number | null;
  Q102A6B5C2: number | null;
  Q103A2B3C1: number | null;
  Q103A2B3C2: number | null;
  Q103A2B4C1: number | null;
  Q103A2B4C2: number | null;
  Q103A2B5C1: number | null;
  Q103A2B5C2: number | null;
  Q103A2B6C1: number | null;
  Q103A2B6C2: number | null;
  Q103A2B7C1: number | null;
  Q103A2B7C2: number | null;
  Q103A2B8C1: number | null;
  Q103A2B8C2: number | null;
  Q103A3B3C1: number | null;
  Q103A3B3C2: number | null;
  Q103A3B4C1: number | null;
  Q103A3B4C2: number | null;
  Q103A3B5C1: number | null;
  Q103A3B5C2: number | null;
  Q103A3B6C1: number | null;
  Q103A3B6C2: number | null;
  Q103A3B7C1: number | null;
  Q103A3B7C2: number | null;
  Q103A3B8C1: number | null;
  Q103A3B8C2: number | null;
  Q103A4B3C1: number | null;
  Q103A4B3C2: number | null;
  Q103A4B4C1: number | null;
  Q103A4B4C2: number | null;
  Q103A4B5C1: number | null;
  Q103A4B5C2: number | null;
  Q103A4B6C1: number | null;
  Q103A4B6C2: number | null;
  Q103A4B7C1: number | null;
  Q103A4B7C2: number | null;
  Q103A4B8C1: number | null;
  Q103A4B8C2: number | null;
  is_complete: boolean;
  page: number;
  created_at: string;
  updated_at: string;
  completed_on: string | null;
}

export type LoginTrackingAction =
  | "FORGOT_PASSWORD_RESET_FAIL"
  | "FORGOT_PASSWORD_RESET_SUCCESS"
  | "FORGOT_PASSWORD_SUBMIT"
  | "LOGIN_FAIL"
  | "LOGIN_SUCCESS"
  | "LOGOUT"
  | "PASSWORD_CHANGE_REQUESTED"
  | "PASSWORD_CHANGE_SUBMITTED";

export interface LoginTracking {
  login_tracking_id: number;
  ip_address: string | null;
  instance_id: number;
  client_id: number;
  date: string;
  action: LoginTrackingAction;
}

export interface LangList {
  lang: Language;
  lang_description: string;
}

export interface InstanceLangJoins {
  lang: Language;
  instance_id: number;
}

export interface Role {
  role_id: number;
  role_name: string;
  role_description: string;
  require_mfa: boolean;
  instance_id: number;
}

export interface UserRoleJoin {
  client_id: number;
  role_id: number;
}

export const ACTION_SCOPES = [
  "reports:view_self",
  "reports:view_aggregate",
  "clients:list_clients",
  "clients:add_client",
  "instance:manage_instance",
  "instance:create_instance",
  "instance:manage_own_instance",
  "licenses:view_licenses",
  "licenses:modify_licenses",
  "tests:take_test",
  "tests:assign_remove_test",
  "tests:delete_test",
  "account:manage_account",
  "clients:edit_clients",
  "tests:create_test",
  "tests:edit_test",
  "tests:view_status",
  "action_plan:create_action_plan",
  "action_plan:edit_action_plan",
  "action_plan:delete_action_plan",
  "resources:view_resources",
  "resources:view_summary_report",
  "reports:view_gap",
  "custom_reports:create_custom_report",
  "custom_reports:get_custom_reports",
  "custom_reports:manage_all",
  "custom_reports:manage_own",
  "custom_reports:manage_complexity",
  "join_code:manage",
] as const;

export enum AnonymityLevel {
  FULL = "FULL",
  PARTIAL = "PARTIAL",
  NONE = "NONE",
}

export type ActionScope = typeof ACTION_SCOPES[number];

export interface Action {
  action_id: ActionScope;
  description: string;
  name: string;
}

export interface RoleActionJoin {
  role_id: number;
  action_id: ActionScope;
}

export const MENU_SCOPES = [
  "dashboard",
  "admin_dashboard",
  "user_dashboard",
  "instance_manager",
  "reports",
  "reports_resiliency_capacity",
  "clients",
  "reports_performance_capacity",
  "reports_flourishing_mindsets",
  "reports_core_competency",
  "reports_nine_habits",
  "reports_seven_well_being",
  "reports_custom_questions",
  "reports_client_list",
  "trends",
  "trends_resiliency_capacity",
  "trends_performance_capacity",
  "trends_seven_well_being_factors",
  "trends_core_competency",
  "trends_flourishing_mindset",
  "trends_nine_habits",
  "trends_flourishing_summary",
  "clients_assign",
  "clients_create",
  "clients_tree",
  "survey_admin",
  "survey_admin_list",
  "edit_language",
  "flourishing_profile",
  "license_manager",
  "client_manager",
  "reports_flourishing_summary",
  "questionnaire_status",
  "anonymity_override",
  "custom_reports",
  "join_code",
] as const;

export type CustomMenuOverride<Str extends string> = `custom_${Str}`;

export type MenuScope = CustomMenuOverride<string> | typeof MENU_SCOPES[number];

export interface Menu {
  menu_id: MenuScope;
  name: string;
  description: string;
}

export interface RoleMenuJoin {
  role_id: number;
  menu_id: MenuScope;
}

export interface FawbstestCust {
  fawbstest_cust_id: number;
  fawbstest_cust_key: string;
  fawbstest_id: number;
  instance_id: number;
  choices: string;
  reverse: boolean;
  question_type: CustomQuestionTypes;
  gap?: boolean;
}

export interface FawbstestCustLang {
  id: number;
  fawbstest_cust_id: number;
  question: string;
  lang: string;
  positive_text?: string | null;
  negative_text?: string | null;
}

export interface YouthResultsCust {
  youth_results_cust_id: number;
  youth_results_id: number;
  clientid: number;
  fawid: number;
  fawbstest_cust_id: number;
  result: string;
}

export interface OrganizationLevel {
  organization_level_id: number;
  level_number: number;
  parent_id?: number | null;
  instance_id: number;
  created_at: string;
  updated_at: string;
}

export interface OrganizationLevelLang {
  level_name: string;
  level_description: string;
  level_id: number;
  lang: Language;
}

export interface Lifeskills {
  lifeskills_id: number;
  client_id: number;
  youth_results_id: number;
  Personal_Power_Confidence: number;
  Personal_Management: number;
  EmotionalSocial_Intel: number;
  LeadershipCourage: number;
  Critical_ThinkerProbl: number;
  ResilientGrowth_Mindset: number;
  MotivatedSelf_Directed_Learner: number;
  Moral_Directedness: number;
  Interpersonal_Collaborative_Skills: number;
  Effective_Problem_Solver: number;
}

export interface NineHabits {
  habits_id: number;
  client_id: number;
  youth_results_id: number;
  Compassionate: number;
  Patient: number;
  Humble: number;
  Joyful: number;
  Honest: number;
  Hopeful: number;
  Considerate: number;
  Forgiving: number;
  Self_Controlled: number;
}

export interface WellBeing {
  wellbeing_id: number;
  client_id: number;
  youth_results_id: number;
  Cognitive: number;
  Social: number;
  Emotional: number;
  Spiritual: number;
  Physical: number;
  Relational: number;
  Personal: number;
}

export type FlourishingCategory4 =
  | "Capable"
  | "Optimal"
  | "Strained"
  | "Vulnerable";

export type FlourishingCategory3 = "Capable" | "Cautious" | "Optimal";

export type FlourishingPotentialCategory3 =
  | "Languishing"
  | "Managing"
  | "Thriving";

export type FlourishingPotentialCategory4 =
  | "Languishing"
  | "Managing"
  | "Surviving"
  | "Thriving";

export interface YouthSubfactor {
  youth_subfactor_id: number;
  fawbstest_id: number;
  client_id: number;
  youth_results_id: number;
  Self_Esteem_Optimistic: number;
  SB_Perspective_Confidence: number;
  Sense_of_Purpose_Direction: number;
  Empathize_Caring_Other_Focused: number;
  Positive_Social_Skills: number;
  Social_Conscience_Altruism: number;
  Emotional_Awareness_Regulation: number;
  Self_Efficacy_Empowered: number;
  Cognitive_Flexibility_Adaptable: number;
  Self_Kindness_Compassion: number;
  Spiritual_Eagerness: number;
  Physically_Aware_Active: number;
  Connectedness: number;
  Positive_Expectations_Support: number;
  Positive_Communication_Respect: number;
  ConnectednessA: number;
  Positive_Exprectations_SupportA: number;
  Positive_Communication_RespectA: number;
  Positive_Friends_Acceptance: number;
  Shated_Values_Peer_Refusal: number;
  Achievement_Motivated: number;
  Competence_Focused: number;
  Confidence_Building: number;
  ConnectednessB: number;
  Positive_Expectations_SupportB: number;
  Positive_Communication_Involvement: number;
  Perseverence: number;
  Managing_Stress: number;
  Perceptiveness: number;
  Growth_Mindset: number;
  Criticism_Change_Receptive: number;
  Honesty_Integrity: number;
  Communication_Listening: number;
  Empowering_Generous: number;
  Interpersonal_Skills: number;
  Diverse_Culture_Receptive: number;
  Managing_Conflict: number;
  Positive_Influence: number;
  Innovative_Entrep: number;
  Knowledgeable_Inuitive: number;
  Critical_Thinker: number;
  Good_Judgement: number;
  Curious_Inquiry_Skills: number;
  Continuous_Deep_Learner: number;
  Self_Esteem_Optimistic1: boolean;
  SB_Perspective_Confidence1: boolean;
  Sense_of_Purpose_Direction1: boolean;
  Empathize_Caring_Other_Focused1: boolean;
  Positive_Social_Skills1: boolean;
  Social_Conscience_Altruism1: boolean;
  Emotional_Awareness_Regulation1: boolean;
  Self_Efficacy_Empowered1: boolean;
  Cognitive_Flexibility_Adaptable1: boolean;
  Self_Kindness_Compassion1: boolean;
  Spiritual_Eagerness1: boolean;
  Physically_Aware_Active1: boolean;
  Connectedness1: boolean;
  Positive_Expectations_Support1: boolean;
  Positive_Communication_Respect1: boolean;
  ConnectednessA1: boolean;
  Positive_Exprectations_SupportA1: boolean;
  Positive_Communication_RespectA1: boolean;
  Positive_Friends_Acceptance1: boolean;
  Shated_Values_Peer_Refusal1: boolean;
  Achievement_Motivated1: boolean;
  Competence_Focused1: boolean;
  Confidence_Building1: boolean;
  ConnectednessB1: boolean;
  Positive_Expectations_SupportB1: boolean;
  Positive_Communication_Involvement1: boolean;
  Perseverence1: boolean;
  Managing_Stress1: boolean;
  Perceptiveness1: boolean;
  Growth_Mindset1: boolean;
  Criticism_Change_Receptive1: boolean;
  Honesty_Integrity1: boolean;
  Communication_Listening1: boolean;
  Empowering_Generous1: boolean;
  Interpersonal_Skills1: boolean;
  Diverse_Culture_Receptive1: boolean;
  Managing_Conflict1: boolean;
  Positive_Influence1: boolean;
  Innovative_Entrep1: boolean;
  Knowledgeable_Inuitive1: boolean;
  Critical_Thinker1: boolean;
  Good_Judgement1: boolean;
  Curious_Inquiry_Skills1: boolean;
  Continuous_Deep_Learner1: boolean;
  Self_Esteem_Optimistic_C3: FlourishingCategory3;
  SB_Perspective_Confidence_C3: FlourishingCategory3;
  Sense_of_Purpose_Direction_C3: FlourishingCategory3;
  Empathize_Caring_Other_Focused_C3: FlourishingCategory3;
  Positive_Social_Skills_C3: FlourishingCategory3;
  Social_Conscience_Altruism_C3: FlourishingCategory3;
  Emotional_Awareness_Regulation_C3: FlourishingCategory3;
  Self_Efficacy_Empowered_C3: FlourishingCategory3;
  Cognitive_Flexibility_Adaptable_C3: FlourishingCategory3;
  Self_Kindness_Compassion_C3: FlourishingCategory3;
  Spiritual_Eagerness_C3: FlourishingCategory3;
  Physically_Aware_Active_C3: FlourishingCategory3;
  Connectedness_C3: FlourishingCategory3;
  Positive_Expectations_Support_C3: FlourishingCategory3;
  Positive_Communication_Respect_C3: FlourishingCategory3;
  ConnectednessA_C3: FlourishingCategory3;
  Positive_Exprectations_SupportA_C3: FlourishingCategory3;
  Positive_Communication_RespectA_C3: FlourishingCategory3;
  Positive_Friends_Acceptance_C3: FlourishingCategory3;
  Shated_Values_Peer_Refusal_C3: FlourishingCategory3;
  Achievement_Motivated_C3: FlourishingCategory3;
  Competence_Focused_C3: FlourishingCategory3;
  Confidence_Building_C3: FlourishingCategory3;
  ConnectednessB_C3: FlourishingCategory3;
  Positive_Expectations_SupportB_C3: FlourishingCategory3;
  Positive_Communication_Involvement_C3: FlourishingCategory3;
  Perseverence_C3: FlourishingCategory3;
  Managing_Stress_C3: FlourishingCategory3;
  Perceptiveness_C3: FlourishingCategory3;
  Growth_Mindset_C3: FlourishingCategory3;
  Criticism_Change_Receptive_C3: FlourishingCategory3;
  Honesty_Integrity_C3: FlourishingCategory3;
  Communication_Listening_C3: FlourishingCategory3;
  Empowering_Generous_C3: FlourishingCategory3;
  Interpersonal_Skills_C3: FlourishingCategory3;
  Diverse_Culture_Receptive_C3: FlourishingCategory3;
  Managing_Conflict_C3: FlourishingCategory3;
  Positive_Influence_C3: FlourishingCategory3;
  Innovative_Entrep_C3: FlourishingCategory3;
  Knowledgeable_Inuitive_C3: FlourishingCategory3;
  Critical_Thinker_C3: FlourishingCategory3;
  Good_Judgement_C3: FlourishingCategory3;
  Curious_Inquiry_Skills_C3: FlourishingCategory3;
  Continuous_Deep_Learner_C3: FlourishingCategory3;
  Self_Esteem_Optimistic_C4: FlourishingCategory4;
  SB_Perspective_Confidence_C4: FlourishingCategory4;
  Sense_of_Purpose_Direction_C4: FlourishingCategory4;
  Empathize_Caring_Other_Focused_C4: FlourishingCategory4;
  Positive_Social_Skills_C4: FlourishingCategory4;
  Social_Conscience_Altruism_C4: FlourishingCategory4;
  Emotional_Awareness_Regulation_C4: FlourishingCategory4;
  Self_Efficacy_Empowered_C4: FlourishingCategory4;
  Cognitive_Flexibility_Adaptable_C4: FlourishingCategory4;
  Self_Kindness_Compassion_C4: FlourishingCategory4;
  Spiritual_Eagerness_C4: FlourishingCategory4;
  Physically_Aware_Active_C4: FlourishingCategory4;
  Connectedness_C4: FlourishingCategory4;
  Positive_Expectations_Support_C4: FlourishingCategory4;
  Positive_Communication_Respect_C4: FlourishingCategory4;
  ConnectednessA_C4: FlourishingCategory4;
  Positive_Exprectations_SupportA_C4: FlourishingCategory4;
  Positive_Communication_RespectA_C4: FlourishingCategory4;
  Positive_Friends_Acceptance_C4: FlourishingCategory4;
  Shated_Values_Peer_Refusal_C4: FlourishingCategory4;
  Achievement_Motivated_C4: FlourishingCategory4;
  Competence_Focused_C4: FlourishingCategory4;
  Confidence_Building_C4: FlourishingCategory4;
  ConnectednessB_C4: FlourishingCategory4;
  Positive_Expectations_SupportB_C4: FlourishingCategory4;
  Positive_Communication_Involvement_C4: FlourishingCategory4;
  Perseverence_C4: FlourishingCategory4;
  Managing_Stress_C4: FlourishingCategory4;
  Perceptiveness_C4: FlourishingCategory4;
  Growth_Mindset_C4: FlourishingCategory4;
  Criticism_Change_Receptive_C4: FlourishingCategory4;
  Honesty_Integrity_C4: FlourishingCategory4;
  Communication_Listening_C4: FlourishingCategory4;
  Empowering_Generous_C4: FlourishingCategory4;
  Interpersonal_Skills_C4: FlourishingCategory4;
  Diverse_Culture_Receptive_C4: FlourishingCategory4;
  Managing_Conflict_C4: FlourishingCategory4;
  Positive_Influence_C4: FlourishingCategory4;
  Innovative_Entrep_C4: FlourishingCategory4;
  Knowledgeable_Inuitive_C4: FlourishingCategory4;
  Critical_Thinker_C4: FlourishingCategory4;
  Good_Judgement_C4: FlourishingCategory4;
  Curious_Inquiry_Skills_C4: FlourishingCategory4;
  Continuous_Deep_Learner_C4: FlourishingCategory4;
  vulnerable4: number;
  strained4: number;
  capable4: number;
  optimal4: number;
  cautious3: number;
  capable3: number;
  optimal3: number;
  pillar1_2: number;
  pillar1: number;
  pillar2: number;
  pillar3: number;
}

export interface YouthFactor {
  youth_factor_id: number;
  fawbstest_id: number;
  client_id: number;
  youth_results_id: number;
  SelfAwareness: number;
  InterpersonalAwareness: number;
  Self_Management: number;
  Self_Care: number;
  CareGiver_Family: number;
  SchollCultureSupport: number;
  PeerCultureSupport: number;
  LearningInfluence: number;
  CommunityInfluence: number;
  InternalSoftSkills: number;
  ExternalSoftSkills: number;
  Mastery_HardSkills: number;
  SelfAwareness1: boolean;
  InterpersonalAwareness1: boolean;
  Self_Management1: boolean;
  Self_Care1: boolean;
  CareGiver_Family1: boolean;
  SchollCultureSupport1: boolean;
  PeerCultureSupport1: boolean;
  LearningInfluence1: boolean;
  CommunityInfluence1: boolean;
  InternalSoftSkills1: boolean;
  ExternalSoftSkills1: boolean;
  Mastery_HardSkills1: boolean;
  flourishing_potential: FlourishingPotentialCategory3;
  flourishing_potential4: FlourishingPotentialCategory4;
}

export interface LicenseLog {
  license_log_id: number;
  instance_id: number;
  license_delta: number;
  license_total: number;
  description: string;
  client_id: number;
  created_at: string;
}

export interface CognitoKeyCache {
  pool_id: string;
  key_value: string;
  expires_at: string;
  created_at: string;
}

export interface ClientField {
  field_key: string;
  is_system_required: boolean;
  is_system_unique: boolean;
  type: string;
  max_length: number;
  is_socio: boolean;
}
export interface InstanceClientField {
  instance_client_field_id: number;
  field_key: string;
  instance_id: number;
  is_required: boolean;
  is_unique_key: boolean;
  is_enabled: boolean;
}
export interface InstanceClientFieldLang {
  instance_client_field_id: number;
  lang: Language;
  name: string;
}
export interface InstanceSocioField {
  instance_socio_field_id: number;
  field_key: string;
  instance_id: number;
  is_required: boolean;
  is_enabled: boolean;
}
export interface InstanceSocioFieldLang {
  instance_socio_field_id: number;
  lang: Language;
  name: string;
}
export interface InstanceSocioOption {
  instance_socio_option_id: number;
  instance_socio_field_id: number;
}
export interface InstanceSocioOptionLang {
  instance_socio_option_id: number;
  lang: Language;
  name: string;
}

export enum OverrideStatus {
  Denied = "DENIED",
  Requested = "REQUESTED",
  Accepted = "ACCEPTED",
}

export interface AnonymityOverride {
  override_id: number;
  requested_client_id: number;
  creating_client_id: number;
  status: OverrideStatus;
  updated_at: Date;
  created_at: Date;
}

export interface CustomReportMetric {
  name?: string;
  description?: string;
  pdfHeaderTitle?: string;
  pdfHeaderDescription?: string;
  pdfFooterTitle?: string;
  pdfFooterDescription?: string;
  factors: string[];
  subFactors: string[];
  customFactors: number[];
}

export type CustomReportJson = Record<string, CustomReportMetric>;

export enum CustomReportComplexity {
  simple = "SIMPLE",
  full = "FULL",
}

export enum CustomReportType {
  individual = "INDIVIDUAL",
  aggregate = "AGGREGATE",
}

export interface FullCustomReport extends CustomReport {
  report: CustomReportLangJson;
  scope: number[];
  lang: Language;
  createdByName?: string;
}

export interface CustomReport {
  custom_report_id: number;
  instance_id: number;
  complexity: CustomReportComplexity;
  created_by: number;
  type: CustomReportType;
}

export interface CustomReportInstanceJoin {
  custom_report_id: number;
  instance_id: number;
}

export interface CustomReportLangJson {
  name?: string;
  pdfHeaderTitle?: string;
  pdfHeaderDescription?: string;
  metrics: CustomReportMetric[];
}

export interface CustomReportLang {
  custom_report_id: number;
  report: CustomReportLangJson;
  lang: Language;
}

export interface CustomReportNestedJson {
  custom_report_id: number;
  name: string;
  report: NestedJson;
  instance_id: number;
}

export interface NestedJson {
  report: CustomReportMetric[];
}

export enum CustomMenuType {
  REPORT = "REPORT",
  TREND = "TREND",
}
export interface CustomMenu {
  custom_menu_id: MenuScope;
  name: string;
  description: string;
  custom_report_id: number;
  type: CustomMenuType;
}

export interface RoleCustomMenuJoin {
  custom_menu_id: MenuScope;
  role_id: number;
}

export enum GapTypes {
  STANDARD = "STANDARD",
  FULL = "FULL",
}

export enum TrendGraphTypes {
  SIMPLE = "SIMPLE",
  COMPLEX = "COMPLEX",
}

export interface JoinCode {
  join_code_id: number;
  instance_id: number;
  organization_level_id: number;
  code: string;
  active: boolean;
  expires_at: number;
  created_by: number;
  created_at: string;
  updated_at: string;
}

export interface JoinCodeFieldJoin {
  join_code_field_id: number;
  join_code_id: number;
  instance_client_field_id: number | null;
  instance_socio_field_id: number | null;
  created_at: string;
  updated_at: string;
}
