import { Box, Button, Collapse, ListItem } from "@mui/material";
import type { ListItemProps } from "@mui/material";
import Link from "next/link";
import { useState } from "react";
import type { FC, ReactNode } from "react";

import ChevronDownIcon from "../icons/ChevronDown";
import ChevronRightIcon from "../icons/ChevronRight";

interface NavItemProps extends ListItemProps {
  active?: boolean;
  children?: ReactNode;
  depth: number;
  icon?: ReactNode;
  info?: ReactNode;
  open?: boolean;
  path?: string;
  title: string;
}

export const NavItem: FC<NavItemProps> = ({
  active = false,
  children,
  depth,
  icon,
  info,
  open: openProp = false,
  path,
  title,
}) => {
  const [open, setOpen] = useState<boolean>(openProp ?? false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 1;
  let paddingY = 2;

  if (depth > 0) {
    paddingLeft = 1 + 2 * depth;
    paddingY = 1;
  }

  // Branch
  if (children) {
    return (
      <ListItem
        disableGutters
        sx={{
          display: "block",
          py: 0,
        }}
      >
        <Button
          endIcon={
            !open ? (
              <ChevronRightIcon fontSize="small" />
            ) : (
              <ChevronDownIcon fontSize="small" />
            )
          }
          onClick={handleToggle}
          startIcon={icon}
          sx={{
            color: "text.secondary",
            fontWeight: "fontWeightMedium",
            justifyContent: "flex-start",
            pl: paddingLeft,
            pr: "8px",
            py: paddingY,
            textAlign: "left",
            textTransform: "none",
            width: "100%",
          }}
          variant="text"
        >
          <Box sx={{ flexGrow: 1 }}>{title}</Box>
          {info}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  // Leaf
  return (
    <ListItem
      disableGutters
      sx={{
        display: "flex",
        py: 0,
      }}
    >
      <Link href={path ?? ""} passHref>
        <Button
          startIcon={icon}
          sx={{
            color: "text.secondary",
            fontWeight: "fontWeightMedium",
            justifyContent: "flex-start",
            pl: paddingLeft,
            pr: "8px",
            py: paddingY,
            textAlign: "left",
            textTransform: "none",
            width: "100%",
            borderRadius: 4,
            ...(active && {
              color: "primary.main",
              fontWeight: "fontWeightBold",
              "& svg": {
                color: "primary.main",
              },
              backgroundColor: "background.default",
            }),
          }}
          variant="text"
        >
          <Box sx={{ flexGrow: 1 }}>{title}</Box>
          {info}
        </Button>
      </Link>
    </ListItem>
  );
};
