import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Button,
} from "@mui/material";
import type { FC } from "react";

import { SlugLink } from "./SlugLink";

import { ROUTES } from "constants/routes";

export const SplashScreen: FC = () => (
  <Box
    sx={{
      alignItems: "center",
      backgroundColor: "background.paper",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "center",
      left: 0,
      p: 3,
      position: "fixed",
      top: 0,
      width: "100%",
      zIndex: 2000,
    }}
  >
    <Card>
      <CardHeader title="Authentication Required" />
      <Divider />
      <CardContent>
        <Box sx={{ mt: 2 }}>
          <Typography color="textSecondary" variant="subtitle2">
            <div style={{ padding: 12 }}>
              Your session has expired, please log in to proceed.
            </div>
          </Typography>
          <SlugLink href={ROUTES.AUTH_LOGIN} passHref>
            <Button color="primary" fullWidth size="large" variant="contained">
              Log In
            </Button>
          </SlugLink>
        </Box>
      </CardContent>
    </Card>
  </Box>
);
