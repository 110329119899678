import type { Theme } from "@mui/material";
import { experimentalStyled } from "@mui/material/styles";
import type { SxProps } from "@mui/system";
import type { FC } from "react";

interface LogoProps {
  sx?: SxProps<Theme>;
}

const LogoRoot = experimentalStyled("svg")`
  width: auto;
`;

const Logo: FC<LogoProps> = (props) => (
  <LogoRoot width="229" height="26" viewBox="0 0 229 26" {...props}>
    <path
      d="M0 23.8758V7.36967C0 6.80643 0.320849 6.49538 0.88982 6.49538H10.2971C10.8703 6.49538 11.1869 6.81064 11.1869 7.36967V7.49576C11.1869 8.059 10.8661 8.37003 10.2971 8.37003H2.07054V14.9565H9.83507C10.4083 14.9565 10.7249 15.2718 10.7249 15.8056V15.8897C10.7249 16.4361 10.404 16.7513 9.83507 16.7513H2.07054V23.88C2.07054 24.4433 1.74969 24.7543 1.18072 24.7543H0.88982C0.316571 24.7501 0 24.4349 0 23.8758Z"
      fill="#888888"
    />
    <path
      d="M14.0654 23.8758V7.36967C14.0654 6.80643 14.3863 6.49538 14.9553 6.49538H15.2462C15.8194 6.49538 16.136 6.81064 16.136 7.36967V22.8629H23.8962C24.4695 22.8629 24.786 23.1781 24.786 23.7371V23.8758C24.786 24.4391 24.4652 24.7501 23.8962 24.7501H14.9553C14.382 24.7501 14.0654 24.4349 14.0654 23.8758Z"
      fill="#888888"
    />
    <path
      d="M24.9834 15.6459C24.9834 9.13923 29.1031 6.28943 33.7276 6.28943C38.2708 6.28943 42.4333 9.13923 42.4333 15.6459C42.4333 22.2786 38.3007 25.0275 33.6762 25.0275C29.0518 25.0275 24.9834 22.2786 24.9834 15.6459ZM40.3584 15.6459C40.3584 10.9803 37.7917 8.17669 33.7276 8.17669C29.5993 8.17669 27.0326 10.9508 27.0326 15.6459C27.0326 20.4124 29.5095 23.1403 33.6762 23.1403C37.8259 23.1403 40.3584 20.4039 40.3584 15.6459Z"
      fill="#888888"
    />
    <path
      d="M45.7402 16.1797V7.36967C45.7402 6.80643 46.0611 6.49538 46.6301 6.49538H46.921C47.4942 6.49538 47.8108 6.81064 47.8108 7.36967V16.1671C47.8108 21.1143 49.5733 23.1024 53.3336 23.1024C57.094 23.1024 58.8565 21.1143 58.8565 16.1797V7.36967C58.8565 6.80643 59.1774 6.49538 59.7463 6.49538H60.0372C60.6105 6.49538 60.9271 6.81064 60.9271 7.36967V16.1797C60.9271 22.2029 58.3218 25.0023 53.3336 25.0023C48.3455 25.0023 45.7402 22.2156 45.7402 16.1797Z"
      fill="#888888"
    />
    <path
      d="M77.2434 24.7501H76.7514C76.2723 24.7501 75.9429 24.5652 75.6734 24.1869L71.2499 18.1678C70.8136 18.1888 70.3558 18.2015 69.881 18.2015H66.7709V23.8716C66.7709 24.4349 66.45 24.7459 65.8811 24.7459H65.5773C65.0041 24.7459 64.6875 24.4307 64.6875 23.8716V7.36546C64.6875 6.80223 65.0083 6.49118 65.5773 6.49118H69.9109C75.4851 6.49118 78.3129 7.84884 78.3129 12.3211C78.3129 15.6333 76.6402 17.2893 73.4787 17.9072L77.8038 23.6783C78.2829 24.3592 78.099 24.7501 77.2434 24.7501ZM76.2252 12.3883C76.2252 9.00893 73.9194 8.3112 69.8681 8.3112H66.758V16.4235H69.8681C73.9194 16.4235 76.2252 15.7678 76.2252 12.3883Z"
      fill="#888888"
    />
    <path
      d="M95.1852 21.3329C94.8815 20.8369 95.0398 20.5132 95.6515 20.2652L95.8611 20.1812C96.3403 19.992 96.644 20.1055 97.0547 20.6688C98.2311 22.2534 100.255 23.1907 102.68 23.1907C105.602 23.1907 107.544 21.9003 107.544 19.8323C107.544 14.7842 95.6815 18.1804 95.6815 11.182C95.6815 8.27336 98.3894 6.22639 102.056 6.22639C105.072 6.22639 107.715 7.46214 108.935 9.50492C109.238 10.0093 109.076 10.3792 108.507 10.5599L108.229 10.6524C107.771 10.7911 107.446 10.6818 107.044 10.169C105.931 8.76515 104.34 8.0674 102.133 8.0674C99.4974 8.0674 97.6836 9.28635 97.6836 11.1862C97.6836 16.3772 109.563 12.7372 109.563 19.8617C109.563 22.9427 106.74 25.0065 102.689 25.0065C99.3263 25.0023 96.49 23.5564 95.1852 21.3329Z"
      fill="#888888"
    />
    <path
      d="M112.318 23.8758V7.36967C112.318 6.80643 112.639 6.49538 113.208 6.49538H113.499C114.072 6.49538 114.389 6.81064 114.389 7.36967V14.553H124.596V7.36967C124.596 6.80643 124.917 6.49538 125.486 6.49538H125.777C126.35 6.49538 126.667 6.81064 126.667 7.36967V23.8758C126.667 24.4391 126.346 24.7501 125.777 24.7501H125.486C124.913 24.7501 124.596 24.4349 124.596 23.8758V16.373H114.389V23.8758C114.389 24.4391 114.068 24.7501 113.499 24.7501H113.208C112.639 24.7501 112.318 24.4349 112.318 23.8758Z"
      fill="#888888"
    />
    <path
      d="M130.696 23.8758V7.36967C130.696 6.80643 131.017 6.49538 131.586 6.49538H131.877C132.45 6.49538 132.767 6.81064 132.767 7.36967V23.8758C132.767 24.4391 132.446 24.7501 131.877 24.7501H131.586C131.017 24.7501 130.696 24.4349 130.696 23.8758Z"
      fill="#888888"
    />
    <path
      d="M136.78 23.8758V7.36967C136.78 6.80643 137.101 6.49538 137.67 6.49538H138.269C138.748 6.49538 139.095 6.6509 139.36 7.04601L149.939 21.6901V7.36967C149.939 6.80643 150.26 6.49538 150.829 6.49538H151.12C151.693 6.49538 152.01 6.81064 152.01 7.36967V23.8758C152.01 24.4391 151.689 24.7501 151.12 24.7501H150.594C150.115 24.7501 149.768 24.5946 149.503 24.1995L138.851 9.45448V23.8716C138.851 24.4349 138.53 24.7459 137.961 24.7459H137.67C137.101 24.7501 136.78 24.4349 136.78 23.8758Z"
      fill="#888888"
    />
    <path
      d="M155.479 15.5702C155.479 9.99669 159.009 6.21378 164.185 6.21378C167.381 6.21378 169.858 7.33183 171.308 9.6268C171.646 10.1606 171.411 10.6188 170.799 10.7617L170.521 10.829C170.003 10.955 169.687 10.7953 169.366 10.3666C168.206 8.80297 166.534 8.0506 164.211 8.0506C160.219 8.0506 157.55 11.0391 157.55 15.587C157.55 20.2316 160.117 23.1361 164.172 23.1361C168.087 23.1361 170.487 20.8915 170.632 16.7639H165.126C164.553 16.7639 164.236 16.4487 164.236 15.8897V15.8056C164.236 15.2592 164.57 14.9565 165.126 14.9565H171.881C172.454 14.9565 172.771 15.1877 172.771 15.6837V16.1082C172.771 21.5893 169.443 24.9603 164.104 24.9603C158.855 24.9519 155.479 21.2698 155.479 15.5702Z"
      fill="#888888"
    />
    <path
      d="M183.444 23.8758V7.36967C183.444 6.80643 183.765 6.49538 184.334 6.49538H184.625C185.198 6.49538 185.515 6.81064 185.515 7.36967V22.8629H193.275C193.848 22.8629 194.165 23.1781 194.165 23.7371V23.8758C194.165 24.4391 193.844 24.7501 193.275 24.7501H184.334C183.765 24.7501 183.444 24.4349 183.444 23.8758Z"
      fill="#888888"
    />
    <path
      d="M196.894 23.8758V7.36967C196.894 6.80643 197.214 6.49538 197.783 6.49538H198.074C198.648 6.49538 198.964 6.81064 198.964 7.36967V23.8758C198.964 24.4391 198.643 24.7501 198.074 24.7501H197.783C197.214 24.7501 196.894 24.4349 196.894 23.8758Z"
      fill="#888888"
    />
    <path
      d="M202.977 23.8758V7.36967C202.977 6.80643 203.297 6.49538 203.866 6.49538H213.274C213.847 6.49538 214.163 6.81064 214.163 7.36967V7.49576C214.163 8.059 213.843 8.37003 213.274 8.37003H205.047V14.9565H212.812C213.385 14.9565 213.701 15.2718 213.701 15.8056V15.8897C213.701 16.4361 213.381 16.7513 212.812 16.7513H205.047V23.88C205.047 24.4433 204.726 24.7543 204.157 24.7543H203.866C203.297 24.7501 202.977 24.4349 202.977 23.8758Z"
      fill="#888888"
    />
    <path
      d="M217.043 23.8758V7.36967C217.043 6.80643 217.364 6.49538 217.933 6.49538H228.11C228.683 6.49538 229 6.81064 229 7.36967V7.50837C229 8.07161 228.679 8.38264 228.11 8.38264H219.113V14.553H227.648C228.221 14.553 228.538 14.8683 228.538 15.4273V15.5114C228.538 16.0746 228.217 16.373 227.648 16.373H219.113V22.8629H228.11C228.683 22.8629 229 23.1781 229 23.7371V23.8758C229 24.4391 228.679 24.7501 228.11 24.7501H217.933C217.364 24.7501 217.043 24.4349 217.043 23.8758Z"
      fill="#888888"
    />
    <path
      d="M101.084 2.79531C101.084 2.79531 100.425 2.80791 99.7667 3.41738C99.1078 4.03106 98.3164 5.65351 96.7592 6.60765C95.2021 7.56179 93.8759 6.84724 93.4951 6.51098C93.1144 6.17052 93.3197 5.64091 93.3197 5.64091C93.3197 5.64091 92.4813 5.39292 93.0845 4.24963C95.8224 0.54236 101.084 2.79531 101.084 2.79531Z"
      fill="#8CC640"
    />
    <path
      d="M96.7598 6.60887C98.1587 5.75141 98.9416 4.35173 99.5619 3.63297C98.8518 4.22983 97.9577 5.68836 96.3791 6.44495C94.9545 7.12588 93.7867 6.56684 93.2861 6.16753C93.3161 6.28523 93.376 6.41133 93.4915 6.5122C93.8808 6.85267 95.2027 7.56301 96.7598 6.60887Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M93.2904 4.75944C95.562 1.26653 100.191 2.53171 101.046 2.80072C101.072 2.80072 101.085 2.79652 101.085 2.79652C101.085 2.79652 95.5278 0.367039 93.0894 4.25085C92.8412 4.72161 92.8369 5.04106 92.9182 5.25122C92.9482 5.26803 92.9824 5.28484 93.0166 5.29745C93.1065 5.12091 93.2091 4.93177 93.2904 4.75944Z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M72.9648 0.126755C72.9648 0.126755 73.8974 0.387356 74.6033 1.50542C75.3049 2.62349 75.8097 5.2337 77.6621 7.17561C79.5144 9.11751 81.6791 8.5921 82.355 8.25164C83.0267 7.91117 82.9411 7.07473 82.9411 7.07473C82.9411 7.07473 84.233 7.0327 83.8138 5.17487C81.3369 -1.13001 72.9648 0.126755 72.9648 0.126755Z"
      fill="#8CC640"
    />
    <path
      d="M77.6626 7.17631C75.9942 5.42776 75.4209 3.1454 74.8135 1.88863C75.5921 3.00669 76.3108 5.41935 78.2658 7.08384C80.0326 8.5844 81.9149 8.22293 82.7833 7.83623C82.6935 7.99595 82.5609 8.14726 82.3556 8.25235C81.6796 8.59281 79.515 9.11822 77.6626 7.17631Z"
      fill="url(#paint2_linear)"
    />
    <path
      d="M83.3261 5.82708C81.4352 -0.00283524 74.3424 0.0770312 73.0162 0.144283C72.982 0.131674 72.9648 0.127467 72.9648 0.127467C72.9648 0.127467 81.8288 -1.26801 83.8095 5.17558C83.9806 5.93637 83.8651 6.39452 83.6684 6.66773C83.617 6.68034 83.5657 6.68874 83.5143 6.70135C83.4459 6.41132 83.3689 6.1087 83.3261 5.82708Z"
      fill="url(#paint3_linear)"
    />
    <path
      d="M96.1691 4.42319C96.1691 4.42319 89.7607 7.87407 88.4516 11.6612C87.8997 13.2542 86.9543 22.1693 86.5821 24.8216H86.3169C86.3169 24.8216 85.9447 13.6409 86.9072 10.7197C88.0666 7.19735 96.1691 4.42319 96.1691 4.42319Z"
      fill="#888888"
    />
    <path
      d="M78.6211 3.6498C78.6211 3.6498 83.7461 7.68493 85.145 11.4342C85.7824 13.1407 85.9664 21.9339 86.0776 24.8216H86.3129C86.3129 24.8216 87.4252 14.759 86.8476 11.1022C86.2701 7.44533 78.6211 3.6498 78.6211 3.6498Z"
      fill="#888888"
    />
    <path
      d="M87.2372 8.21033C88.3287 8.21033 89.2136 7.3409 89.2136 6.26842C89.2136 5.19593 88.3287 4.32652 87.2372 4.32652C86.1456 4.32652 85.2607 5.19593 85.2607 6.26842C85.2607 7.3409 86.1456 8.21033 87.2372 8.21033Z"
      fill="#888888"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="96.9973"
        y1="2.931"
        x2="95.7592"
        y2="7.37115"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1067" stopColor="#A8D15E" />
        <stop offset="0.4326" stopColor="#CFE28E" />
        <stop offset="0.9045" stopColor="#8DC640" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="98.46"
        y1="1.27153"
        x2="95.2979"
        y2="6.66123"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0899" stopColor="#A8D15E" />
        <stop offset="0.309" stopColor="#CFE28E" />
        <stop offset="0.6742" stopColor="#8DC640" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="78.7301"
        y1="1.8146"
        x2="78.8423"
        y2="8.60776"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1067" stopColor="#A8D15E" />
        <stop offset="0.4326" stopColor="#CFE28E" />
        <stop offset="0.9045" stopColor="#8DC640" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="77.2835"
        y1="-1.09684"
        x2="79.7705"
        y2="7.75234"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0899" stopColor="#A8D15E" />
        <stop offset="0.309" stopColor="#CFE28E" />
        <stop offset="0.6742" stopColor="#8DC640" />
      </linearGradient>
    </defs>
  </LogoRoot>
);

export default Logo;
