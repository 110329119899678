import { getInitials } from "./getInitials";

export const stringToColor = (string: string) => {
  let hash = 0;

  for (let i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (let i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }

  return color;
};

export const stringAvatar = (name = "") => ({
  sx: {
    bgcolor: stringToColor(name),
    height: 32,
    width: 32,
    fontSize: 15,
    fontWeight: 600,
  },
  children: getInitials(name),
});
