import { AppBar, Box, IconButton, Link, Toolbar } from "@mui/material";
import type { AppBarProps } from "@mui/material";
import { experimentalStyled } from "@mui/material/styles";
import download from "downloadjs";
import Image from "next/image";
import type { FC } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { LanguagePopover } from "./LanguagePopover";

import { RoundedButton } from "components/RoundedButton";
import { AccountPopover } from "components/dashboard/AccountPopover";

import { useAuth } from "hooks/useAuth";
import { useInstanceData } from "hooks/useInstanceData";
import MenuIcon from "icons/Menu";

import { GapTypes } from "types/Tables";

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
  loading?: boolean;
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "none",
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: "none",
  }),
  zIndex: theme.zIndex.drawer + 100,
}));

const LinkSyling = experimentalStyled(Link)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === "dark" && {}),
  color: theme.palette.primary.contrastText,
}));

export const DashboardNavbar: FC<DashboardNavbarProps> = ({
  onSidebarMobileOpen,
  ...other
}) => {
  const { t } = useTranslation();
  const { permissions } = useAuth();
  const { instance } = useInstanceData();

  const seeResources = React.useMemo(
    () =>
      permissions.actions.some(
        (action) => action.action_id === "resources:view_resources"
      ),
    [permissions.actions]
  );

  return (
    <Box displayPrint="none">
      <DashboardNavbarRoot {...other}>
        <Toolbar sx={{ minHeight: 64 }}>
          <IconButton
            sx={{ display: { lg: "none", xs: "flex" }, mr: 1 }}
            color="inherit"
            onClick={onSidebarMobileOpen}
          >
            <MenuIcon fontSize="small" />
          </IconButton>

          <Box>
            <Image
              alt="FL Logo"
              src="/logos/flourish-logo.svg"
              height={26}
              width={230}
            />
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              ml: 2,
            }}
          />
          {seeResources && (
            <RoundedButton
              color="inherit"
              onClick={async () => {
                const resourcePDF = await fetch(
                  `${process.env.NEXT_PUBLIC_URL ?? ""}/growthActionPlan/${
                    instance?.gap_type === GapTypes.FULL
                      ? "GrowthActionPlanGuide.pdf"
                      : "GeneralGrowthActionPlanGuide.pdf"
                  }`
                ).then(async (tr) => tr.blob());
                download(resourcePDF, `resource.pdf`, "application/pdf");
              }}
            >
              {t("nav_bar.resource.button")}
            </RoundedButton>
          )}

          <RoundedButton color="inherit">
            <LinkSyling
              href="https://www.youtube.com/playlist?list=PL5jh6wvheH-a-XOkHdKIT_u7Y1f9RhwoW"
              rel="noopener noreferrer"
              target="_blank"
            >
              {t("nav_bar.help.button")}
            </LinkSyling>
          </RoundedButton>
          <LanguagePopover />
          <Box sx={{ ml: 2 }}>
            <AccountPopover />
          </Box>
        </Toolbar>
      </DashboardNavbarRoot>
    </Box>
  );
};
