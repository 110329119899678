import Cookie from "js-cookie";
import { useRouter } from "next/router";
import * as React from "react";

import { Loading } from "components/Loading";
import { SplashScreen } from "components/SplashScreen";

import { useAuth } from "hooks/useAuth";

export const AuthenticatedRoute: React.FC = ({ children }) => {
  const auth = useAuth();
  const router = useRouter();

  const slug = router.query.slug;
  const cSlug = Cookie.get("slug");

  React.useEffect(() => {
    if (
      typeof slug === "string" &&
      typeof cSlug === "string" &&
      slug !== cSlug
    ) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      router.push(router.route.replace("[slug]", cSlug));
    }
  }, [slug, cSlug, router]);

  if (!auth.isInitialized) {
    return <Loading open />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{auth.isAuthenticated ? children : <SplashScreen />}</>;
};
